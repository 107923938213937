<template>
  <div>
    <b-card-group deck>
      <b-card header-tag="header" footer-tag="footer">
        <template v-slot:header>
          <h6 class="mb-0">Thêm cấu hình OKR</h6>
        </template>
        <div class="content">
          <b-row>
            <b-col>
              <b-form-group label="Tiêu đề (*)">
                <b-form-input v-model="params.title"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Mô tả ngắn">
                <b-form-input v-model="params.caption"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group label="Mục tiêu (*)">
                <b-form-input @keydown="numbersOnly($event)" type="number" v-model="params.target_value"></b-form-input>
                <span class="d-block text-right text-primary" v-if="params.target_value">Mục tiêu: {{ numberFormat(parseInt(params.target_value)) }}</span>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Loại dịch vụ (*)">
                <b-form-select :options="services" v-model="params.execute_class"></b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group label="Trạng thái (*)">
                <b-form-select :options="status" v-model="params.status"></b-form-select>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Thời gian bắt đầu (*)">
                <b-form-input
                    :id="`type-date`"
                    :type="`date`"
                    v-model="params.start_date"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Thời gian kết thúc (*)">
                <b-form-input
                    :id="`type-date`"
                    :type="`date`"
                    v-model="params.end_date"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <div class="text-center">
                <b-button type="button" variant="primary" @click="createConfig()" ><i class="fas fa-save"></i> Lưu</b-button>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-card-group>
  </div>
</template>

<script>
import Notifications from "vue-notification";
import Common from "../../../../core/mixins/common";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Vue from "vue";

Vue.use(Notifications);

const OkrRepository = RepositoryFactory.get("monitor");

export default {
  mixins: [Common],
  data() {
    return {
      status: [
        { value: "ACTIVATE", text: "Hoạt động" },
        { value: "DEACTIVATE", text: "Không hoạt động" }
      ],
      services: [],
      params: {
        title: null,
        caption: null,
        target_value: 0,
        execute_class: null,
        status: null,
        start_date: this.getFirstDayOfMonth(),
        end_date: this.getCurrentDay(),
      }
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Thêm cấu hình OKR", route: "create" },
    ]);
  },
  methods: {
    async createConfig() {
      this.$bus.$emit("show-loading", true);
      let params = this.convert(this.params);
      let response = await OkrRepository.createOkr(params);
      this.$bus.$emit("show-loading", false);
      let body = response.data;

      if (body.error_code) {
        let data = body.data;
        if (data.length > 0) {
          let self = this;
          data.forEach(function(item) {
            self.notifyAlert("warn", item[0]);
          })
        } else {
          this.notifyAlert("warn", body.message);
        }

        return true;
      }

      this.notifyAlert("success", body.message);
      window.location = "/#/tools/okr";
    },

    async getServices() {
      this.$bus.$emit("show-loading", true);
      this.services = [];
      let response = await OkrRepository.listServiceOkr();

      this.$bus.$emit("show-loading", false);
      let body = response.data.data;
      if (body.error) {
        this.notifyAlert("warn", response.data.message);
        return true;
      }

      let services = body.data;

      if (services.length > 1) {
        services.splice(0, 1);
      }

      this.services = services;
    },
    convert(params) {
      Object.keys(params).forEach(key => {
        if (params[key] === null || params[key] == '') {
          delete params[key];
        }
      });
      return params;
    },
  },
  created() {
    this.getServices();
  },
  watch: {

  },
};
</script>
